@font-face {
    font-family: AllertaStencil;
    src: url('assets/fonts/Allerta_Stencil/AllertaStencil-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Steamy;
    src: url('assets/fonts/Steamy/Steamy.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: IBMPlexMono;
    src: url('assets/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.transition {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}

.default {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    transform: scale(1);
}

.zoom {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    transform: scale(1.22);
}

.rotation {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transform: rotate(-180deg);
}

.horizontal-translation {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    transform: translateX(-300%);
}

.vertical-translation {
    -webkit-transition: all 1.5s ease;
    transition: all 1.5s ease;
    transform: translateY(-500%);
}